import React from 'react'
import PropTypes from 'prop-types'
import ButtonMUI from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
    display: 'inline',
  },

  buttonProgress: {
    display: 'flex',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
}))

const Button = React.forwardRef((props, ref) => {
  const classes = useStyles()

  const { children, size, loading, loadingColor, loadingSize, disabled, style, ...rest } = props

  return (
    <span className={classes.wrapper}>
      <ButtonMUI
        style={{ ...style }}
        {...(size !== 'xs' && { size })}
        {...(size === 'xs' && {
          style: { ...style, height: '32px', fontSize: '12px', padding: '0 8px 0 8px' },
        })}
        disabled={loading || disabled}
        ref={ref}
        {...rest}
      >
        {children}
      </ButtonMUI>
      {loading && (
        <div className={classes.buttonProgress}>
          <CircularProgress color={loadingColor} size={loadingSize} />
        </div>
      )}
    </span>
  )
})

Button.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  loadingColor: PropTypes.oneOf(['primary', 'secondary']),
  loadingSize: PropTypes.number,
  size: PropTypes.oneOf(['xs', 'small', 'medium', 'large']),
  style: PropTypes.object,
}
Button.defaultProps = {
  disabled: false,
  size: 'medium',
  loading: false,
  loadingColor: 'primary',
  loadingSize: 24,
  style: {},
}

export default Button
