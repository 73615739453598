import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import styles from './Container.style'

const Container = ({ classes, children }) => <div className={classes.root}>{children}</div>

Container.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Container)
