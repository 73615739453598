import React from 'react'
import PropTypes from 'prop-types'
import { Typography as MuiTypography } from '@material-ui/core'
import _ from 'lodash'
import { letClinicFont as fontConfig, newLetClinicPalette as palette } from '../../Theme'
import useStyles from './Typography.style'

const Typography = ({ children, variant, fontWeight, color, className, ...rest }) => {
  const classes = useStyles({
    fontWeight: fontWeight ? fontConfig.weight[fontWeight] : undefined,
    color: _.get(palette, color),
  })
  const extraVariants = ['body3', 'alert1']
  const processedVariant = extraVariants.includes(variant)
    ? { variant: 'body1', className: variant }
    : { variant, className: '' }

  return (
    <MuiTypography
      variant={processedVariant.variant}
      className={`${classes.root} ${
        processedVariant.className ? classes[processedVariant.className] : ''
      } ${className || ''}`}
      {...rest}
    >
      {children}
    </MuiTypography>
  )
}

Typography.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  fontWeight: PropTypes.oneOf(['regular', 'bold', 'semibold']),
  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'body1',
    'body2',
    'body3',
    'alert1',
  ]),
}

Typography.defaultProps = {
  children: undefined,
  className: '',
  fontWeight: 'regular',
  variant: 'body1',
  color: 'text.graphite',
}

export default Typography
