export default ({ palette, breakpoints }) => ({
  root: {
    borderBottom: `1px solid #C4C4C4`,
    margin: '16px 0',
    '&.without-line': {
      borderBottom: 'none',
    },
    [breakpoints.up('sm')]: {
      margin: '24px 0',
    },
  },
  text: {
    fontSize: '12px',
    fontWeight: '700',
    lineHeigh: '18px',
    borderBottom: `3px solid ${palette.secondary.main}`,
    paddingBottom: '2px',
    [breakpoints.up('sm')]: {
      fontSize: '16px',
      lineHeigh: '24px',
    },
  },
})
