import React from 'react'
import PropTypes from 'prop-types'
import { Paper, Typography } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/InfoRounded'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/styles'
import clsx from 'clsx'

const FormToolTip = ({ classes, show, message, onClose, withMargins }) => {
  return !show ? null : (
    <Paper elevation={0} className={clsx(classes.container, withMargins && classes.margins)}>
      <InfoIcon className={classes.icon} />
      <Typography component="p" className={classes.text}>
        {message}
      </Typography>
      <CloseIcon className={classes.closeIcon} onClick={onClose} />
    </Paper>
  )
}

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.palette.info.background,
    padding: theme.spacing(2, 4.2),
  },
  margins: {
    marginBottom: theme.spacing(7),
  },
  icon: {
    marginRight: theme.spacing(2),
    fontSize: theme.typography.pxToRem(24),
    color: theme.palette.info.icon,
  },
  text: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(16),
  },
  closeIcon: {
    fontSize: theme.typography.pxToRem(18),
    color: '#383838',
    marginLeft: 'auto',
  },
})

FormToolTip.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  show: PropTypes.bool.isRequired,
  withMargins: PropTypes.bool,
}

FormToolTip.defaultProps = {
  onClose: () => {},
  withMargins: true,
}

export default withStyles(styles)(FormToolTip)
