import React from 'react'
import PropTypes from 'prop-types'
import { Typography, withStyles } from '@material-ui/core'
import styles from './AsideForm.style'

const AsideForm = ({ classes, textContent, title }) => (
  <div className={classes.formInfoBorder}>
    <div className={classes.formInfoContainer}>
      <div className={classes.formInfoText}>
        <Typography
          variant="h2"
          color="primary"
          component="h1"
          gutterBottom
          className={classes.text}
        >
          {title}
        </Typography>
        <Typography variant="subtitle2" component="p" gutterBottom className={classes.text}>
          {textContent}
        </Typography>
      </div>
    </div>
  </div>
)

AsideForm.propTypes = {
  classes: PropTypes.object.isRequired,
  textContent: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default withStyles(styles)(AsideForm)
