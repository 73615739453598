import React from "react";
import PropTypes from "prop-types";
import Cleave from "cleave.js/react";
import TextField from "@material-ui/core/TextField";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withStyles } from "@material-ui/styles";
import FormHelperText from "../FormHelperText";

const styles = (theme) => {
  return {
    root: (props) => {
      if (props?.variant === "outlined") {
        return { ...props.rootStyle };
      }
      return {
        ...props.rootStyle,
        [theme.breakpoints.down("xs")]: {
          // https://codepen.io/jakob-e/pen/yakBwJ
          width: "133%",
          transformOrigin: "top left",
          transform: "scale(0.75)", //  12px / 16px
          //  size up
          // width: '240px', // 180px / 0.75
          height: "32px", //  24px / 0.75
          padding: "5.333333px", //   4px / 0.75
          // borderRadius: '6.666667px', //   5px / 0.75
          // borderWidth: '1.333333px', //   1px / 0.75

          //  size down
          marginRight: "-60px", // 240px * 0.25
          marginBottom: "-8px", //  32px * 0.25
        },
      };
    },
  };
};

const CleaveWrapper = ({ inputRef, ...rest }) => (
  <Cleave htmlRef={inputRef} {...rest} />
);
CleaveWrapper.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
};

const InputField = React.forwardRef(
  (
    {
      startAdornment,
      inputProps,
      withMask,
      error,
      helperText,
      classes,
      rootStyle,
      ...rest
    },
    ref
  ) => {
    const xsSize = useMediaQuery(useTheme().breakpoints.down("xs"));

    return (
      <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
        {withMask ? (
          <TextField
            autoComplete="off"
            {...rest}
            error={error}
            InputLabelProps={{ style: { fontSize: xsSize ? 12 : 15 } }}
            InputProps={{
              classes,
              inputProps,
              inputComponent: CleaveWrapper,
              ref,
            }}
          />
        ) : (
          <TextField
            autoComplete="off"
            {...rest}
            error={error}
            InputLabelProps={{ style: { fontSize: xsSize ? 12 : 15 } }}
            InputProps={{
              classes,
              inputProps,
              startAdornment,
              ref,
            }}
          />
        )}
        <FormHelperText error={error} message={helperText} />
      </div>
    );
  }
);
InputField.propTypes = {
  classes: PropTypes.object.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.any,
  inputProps: PropTypes.object,
  rootStyle: PropTypes.object,
  startAdornment: PropTypes.element,
  withMask: PropTypes.bool,
};
InputField.defaultProps = {
  error: false,
  helperText: undefined,
  inputProps: {},
  rootStyle: {},
  withMask: false,
  startAdornment: null,
};
export default withStyles(styles)(InputField);
