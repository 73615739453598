import React from 'react'
import PropTypes from 'prop-types'
import { FormHelperText as MaterialFormHelperText, Paper } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/WarningRounded'
import { withStyles } from '@material-ui/styles'

const defaultHelperText = 'Campo obrigatório.'

const FormHelperText = ({ classes, error, message, type }) => {
  if (type === 'small')
    return !error ? null : (
      <MaterialFormHelperText className={classes.errorMessageContainer}>
        <WarningIcon className={classes.warningIcon} />
        {message}
      </MaterialFormHelperText>
    )
  return !error ? null : (
    <Paper elevation={0} className={classes.bigErrorMessageContainer}>
      <WarningIcon className={classes.warningIcon} style={{ fontSize: '24px' }} />
      {message}
    </Paper>
  )
}

const styles = theme => ({
  bigErrorMessageContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: '#383838',
    backgroundColor: theme.palette.error.background,
    padding: '10px 40px',
    marginBottom: '24px',
  },
  errorMessageContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(10),
    color: theme.palette.error.main,
  },
  warningIcon: {
    marginRight: theme.spacing(0.6),
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.error.icon,
  },
})

FormHelperText.propTypes = {
  classes: PropTypes.object.isRequired,
  error: PropTypes.bool.isRequired,
  message: PropTypes.any,
  type: PropTypes.oneOf(['small', 'big']),
}

FormHelperText.defaultProps = {
  message: defaultHelperText,
  type: 'small',
}

export default withStyles(styles)(FormHelperText)
