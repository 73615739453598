import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import FilterListIcon from '@material-ui/icons/FilterList'
import Drawer from '@material-ui/core/Drawer'
import { withStyles } from '@material-ui/styles'
import CloseIcon from '@material-ui/icons/Close'
import { Grid, Typography, Chip, useMediaQuery, useTheme } from '@material-ui/core'
import Button from './Button'

const FilterButton = ({ classes, title, anchor, filter, onFilter, data, Icon, btnProps }) => {
  const [filterOpen, setFilterOpen] = useState(false)
  const [activatedFilters, setActivatedFilters] = useState(data)
  const initialFilter = useRef(data)
  const xsSize = useMediaQuery(useTheme().breakpoints.down('xs'))

  const resetFilter = () => {
    setActivatedFilters(initialFilter.current)
  }

  useEffect(() => {
    onFilter(activatedFilters)
    // eslint-disable-next-line
  }, [activatedFilters])

  // useEffect(() => {
  // setActivatedFilters(data)
  // }, [data])

  return (
    <>
      <Button {...btnProps} onClick={() => setFilterOpen(!filterOpen)}>
        <Icon />
      </Button>
      <Drawer
        anchor={anchor}
        open={filterOpen}
        onClose={() => setFilterOpen(false)}
        style={{ zIndex: 4000 }}
      >
        <div className={classes.drawer}>
          <Grid container spacing={0} className={classes.paddingBody}>
            <Grid xs={12} item>
              <CloseIcon style={{ cursor: 'pointer' }} onClick={() => setFilterOpen(false)} />
            </Grid>
            <Grid
              xs={12}
              item
              style={{ marginTop: xsSize ? '30px' : '50px', marginBottom: '35px' }}
            >
              <Typography variant={xsSize ? 'body1' : 'h6'}>
                <b>{title}</b>
              </Typography>
            </Grid>
            {filter.map((fil, idx1) => (
              <Grid xs={12} item key={fil.id || idx1}>
                <Typography variant="body1" color="secondary" style={{ marginBottom: '10px' }}>
                  <b>{fil.name}</b>
                </Typography>
                {fil.filters.map((options, idx2) => (
                  <Chip
                    color={activatedFilters.includes(options.status) ? 'secondary' : 'default'}
                    className={classes.chipMargin}
                    key={options.id || idx2}
                    label={options.label}
                    onClick={() => {
                      if (activatedFilters.includes(options.status)) {
                        if (!!fil.constant === false)
                          setActivatedFilters(activatedFilters.filter(af => af !== options.status))
                      } else if (fil.exclusive) {
                        // console.log(fil, activatedFilters, options.status)

                        // const newFil = [
                        //   ...activatedFilters.filter(
                        //     f => !fil.filters.map(a => a.status).includes(f),
                        //   ),
                        //   options.status,
                        // ]

                        // setActivatedFilters(newFil)
                        setActivatedFilters([options.status])
                      } else {
                        setActivatedFilters([...activatedFilters, options.status])
                      }
                    }}
                  />
                ))}
              </Grid>
            ))}
          </Grid>
          <hr />
          <Grid xs={12} item style={{ marginLeft: '50px', marginTop: '30px' }}>
            <Button variant="text" onClick={resetFilter}>
              <Typography variant="button" style={{ textDecoration: 'underline' }}>
                Remover
              </Typography>
            </Button>
          </Grid>
        </div>
      </Drawer>
    </>
  )
}

const styles = theme => ({
  drawer: {
    width: '510px',
    maxWidth: '510px',
    // zIndex: 5000,
  },
  paddingBody: { padding: '50px 70px' },
  [theme.breakpoints.down('xs')]: {
    paddingBody: { padding: '20px' },
  },
  chipMargin: {
    marginRight: '20px',
    marginBottom: '20px',
    paddingRight: '20px',
    paddingLeft: '20px',
  },
})

FilterButton.propTypes = {
  anchor: PropTypes.string,
  btnProps: PropTypes.object,

  classes: PropTypes.object.isRequired,
  data: PropTypes.array,
  filter: PropTypes.any.isRequired,
  Icon: PropTypes.elementType,
  onFilter: PropTypes.func.isRequired,
  title: PropTypes.string,
}
FilterButton.defaultProps = {
  anchor: 'right',
  btnProps: { color: 'secondary', variant: 'contained' },
  title: 'Aplicar filtros',
  data: [],
  Icon: <FilterListIcon />,
}

export default withStyles(styles)(FilterButton)
