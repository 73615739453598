import { makeStyles } from '@material-ui/core/styles'
import { letClinicFont as fontConfig } from '../../Theme'

export default makeStyles(({ typography, breakpoints }) => ({
  root: {
    fontWeight: ({ fontWeight }) => fontWeight || 'inherit',
    color: ({ color }) => color,
  },
  body3: {
    fontSize: typography.pxToRem(fontConfig.size.font10),
    lineHeight: typography.pxToRem(fontConfig.size.font16),
    fontWeight: fontConfig.weight.regular,
    [breakpoints.up('sm')]: {
      fontSize: typography.pxToRem(fontConfig.size.font12),
      lineHeight: typography.pxToRem(fontConfig.size.font18),
    },
  },
  alert1: {
    fontSize: typography.pxToRem(fontConfig.size.font10),
    lineHeight: typography.pxToRem(fontConfig.size.font14),
    fontWeight: fontConfig.weight.regular,
    [breakpoints.up('sm')]: {
      fontSize: typography.pxToRem(fontConfig.size.font12),
      lineHeight: typography.pxToRem(fontConfig.size.font16),
    },
  },
}))
