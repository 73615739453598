import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'

const XsButton = React.forwardRef((props, ref) => {
  const { children } = props
  return (
    <Button
      ref={ref}
      {...props}
      style={{ height: '32px', fontSize: '12px', padding: '0 8px 0 8px' }}
    >
      {children}
    </Button>
  )
})

XsButton.propTypes = {
  children: PropTypes.array.isRequired,
}

export default XsButton
