export default theme => ({
  formInfoBorder: {
    position: 'relative',
    '&:before': {
      content: '""',
      left: '-10px',
      top: '-10px',
      position: 'absolute',
      width: '250px',
      height: '280px',
      display: 'block',
      borderRadius: `${theme.typography.pxToRem(110)} 0`,
      background: theme.palette.primary.main,
    },
  },
  formInfoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '500px',
    height: '570px',
    position: 'relative',
    background: theme.palette.bg.main,
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.primary.main}`,
    borderRadius: `${theme.typography.pxToRem(110)} 0`,
    zIndex: 10,
  },
  formInfoText: {
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(0, 6),
    },
    padding: theme.spacing(0, 8),
  },
  text: {
    padding: theme.spacing(0, 6),
  },
})
