import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, withStyles } from '@material-ui/core'
import styles from './Separator.style'

const Separator = ({ classes, text, withoutLine }) => (
  <Grid container spacing={0} className={`${classes.root} ${withoutLine ? 'without-line' : ''}`}>
    <Typography className={classes.text}>{text}</Typography>
  </Grid>
)

Separator.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string,
  withoutLine: PropTypes.bool,
}

Separator.defaultProps = {
  text: '',
  withoutLine: false,
}

export default withStyles(styles)(Separator)
