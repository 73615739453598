import React from 'react'
import PropTypes from 'prop-types'
import { Avatar as AvatarMUI } from '@material-ui/core'

const Avatar = ({ src, alt, type, style }) => {
  const size = {
    large: { width: 177, height: 177 },
    medium: { width: 107, height: 107 },
    small: { width: 36, height: 36 },
  }
  return (
    <AvatarMUI
      src={src}
      alt={alt}
      style={{ ...size[type], marginLeft: 'auto', marginRight: 'auto', ...style }}
    />
  )
}

Avatar.propTypes = {
  alt: PropTypes.string.isRequired,
  src: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.oneOf(['large', 'medium', 'small']),
}

Avatar.defaultProps = {
  type: 'large',
  src: '',
  style: {},
}

export default Avatar
